.subtitle {font-family: arial;}
.darkbg{background-color: #0000008f; padding:10px}
.landing-container {
    text-align: center;
    background: linear-gradient(to bottom, white 100%, black 100%);
    height: 100vh;
    display: flex;
    flex-direction: column;
    color: #333;
    font-family: 'Arial', sans-serif;
    width: 102%!important;
  }
  
  .landing-header {
    background: url('/src/assets/images/back1.webp') no-repeat center center!important;
    background-size: cover!important;
    background-position: center!important;
    color: white; /* Para que el texto contraste con el fondo oscuro */
    min-height: 20vh;
  }

  .landing-header {
    padding: 20px;
  }
  
  .title {
    font-size: 3rem;
    font-weight: bold;
    color:#fff;
  }
  
  .subtitle {
    font-size: 1.5rem;
    margin-top: 10px;
  }
  
  .landing-main {
    padding: 20px;
    padding-bottom: 0px;
    margin:0 50px!important;
    text-align: center;
  }
  
  .motivation {
    margin: 20px 0;
  }
  
  .quote {
    font-size: 1.25rem;
    font-style: italic;
    color: #555;
  }
  
  .simulation .button {
    text-decoration: none;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s;
    cursor: pointer;
    max-width: 300px;
  }
  
  .simulation .button:hover {
    background-color: #0056b3;
  }
  
  .simulation-results {
    margin-top: 20px;
    text-align: left;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .resultado-final {
    font-size: 1.5rem;
    font-weight: bold;
    color: green;
    margin-top: 20px;
  }
  
  .landing-footer {
    padding: 10px;
    font-size: 0.9rem;
    background-color: #f5f7fa;
  }

.button {
    transition: transform 0.3s ease, background-color 0.3s ease;
    background-color: #f44336!important; /* Un rojo oscuro */
}
.button:hover {
    /* transform: scale(1.1); */
    background-color: #d32f2f!important; /* Un rojo oscuro más llamativo */
}

.imagemilani{ width: 33%; float:left}

.motivation-content,
.simulation-content {
  display: flex;
  align-items: center;
  gap: 20px; /* Espaciado entre la imagen y el texto */
}

.image {
  max-width: 100px; /* Tamaño máximo de la imagen */
  height: auto; /* Mantener la proporción */
  border-radius: 10px; /* Opcional: bordes redondeados */
}

.quote,
.simulation-content p {
  text-align: left; /* Alineación del texto a la izquierda */
  font-size: 1.25rem; /* Tamaño del texto */
  color: #333; /* Color del texto */
}

.simulation-text{font-size: 0.8rem!important;}

.simulation {
    padding: 0px, 20px;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Espaciado interno entre los elementos */
  }
  
  .simulation-results {
    max-height: 30vh;
    overflow-y: auto;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.9); /* Fondo claro */
    border-radius: 5px;
  }

    
  .milani-image {
    max-width: 150px; /* Ajusta el tamaño de la imagen */
    height: auto;
    flex-shrink: 0; /* Evita que la imagen se reduzca demasiado */
  }

  .milani-content {
    display: flex;
    align-items: flex-start; /* Alinea elementos al inicio verticalmente */
    gap: 20px; /* Espacio entre imagen y texto */
    flex-wrap: nowrap; /* Evita que los elementos se vayan a filas diferentes */
    flex-direction: column;
  }


  .milani-block{
    background: url('/src/assets/images/milani.jpg') no-repeat center center;
    background-size: cover;
    background-position-y: 22%;
  }
  
   .milani-content p {
    font-size: 1rem;
    line-height: 1.6;
    color: #fff;
    text-align: justify;
    flex: 1;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
    z-index: 3;
  } 

  .milani-info {
    position: relative; /* Necesario para la pseudo-clase ::before */
    z-index: 2; /* Asegura que el contenido esté encima de la capa oscura */
  }

  .milani-info::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Capa negra con opacidad */
    z-index: 1; /* Asegura que esté debajo del contenido */
  }
  
  .milani-title {color:#fff; text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);z-index: 3;}


  
  @media (max-width: 768px) {
    .milani-content {
      flex-direction: column; /* En pantallas pequeñas, organiza en columna */
      align-items: center;
    }
  
    .milani-image {
      max-width: 100px; /* Reduce tamaño de la imagen en móviles */
    }
  
    .milani-content p {
      text-align: center; /* Centra el texto en pantallas pequeñas */
    }
    .landing-main {
        padding: 20px;
        padding-bottom: 0px;
        margin:0 10px!important;
        text-align: center;
      }
    .simulation .button { max-width: 100%;}
  }
  
