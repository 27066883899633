.dashboard-form {
    max-width: 500px;
    margin: 0 auto;
  }
  
  .dashboard-form .form-label {
    font-weight: bold;
  }
  
  .dashboard-form .btn {
    margin-top: 15px;
  }
  