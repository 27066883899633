/* Fondo del offcanvas */
.offcanvas {
    background-color: rgba(0, 0, 0, 0.9); /* Negro semitransparente */
    color: white;
    width: 75%; /* Ocupar solo el 75% del ancho */
  }
  
  /* Transición del offcanvas */
  .offcanvas {
    transition: transform 0.3s ease-in-out, visibility 0.3s;
  }
  
  /* Texto del menú */
  .offcanvas .nav-link {
    color: white;
    font-size: 1.2rem;
    padding: 10px 15px;
    text-align: center;
  }
  
  /* Botón de cierre */
  .btn-close-white {
    filter: invert(1);
  }
  
  /* Fondo del offcanvas con transparencia y blur */
.offcanvas {
    background-color: rgba(0, 0, 0, 0.8)!important; /* Fondo negro semitransparente */
    backdrop-filter: blur(10px)!important; /* Efecto de desenfoque */
    color: white!important; /* Aseguramos que el texto sea legible */
    width: 100vw!important; /* Ocupa el 75% de la pantalla */
  }
  
  
  
  /* Ajustamos el scroll interno del menú */
  .offcanvas-body {
    overflow-y: auto!important;
  }
  
  /* Animación de transición */
  .offcanvas {
    transition: transform 0.3s ease-in-out, visibility 0.3s!important;
  }
  