  /* Imagen responsiva */
  img.img-fluid {
    max-height: 300px;
    object-fit: cover;
    width: 100%;
  }
  
  .event-header h1 {
    color: rgb(0, 0, 0); /* El color principal del texto */
    text-shadow: 2px 2px 8px rgba(177, 176, 176, 0.7); /* Sombra */
    font-family: fantasy;
  }
  
  .event-header p {
    color:black;
    
  }
  
  /* Imagen como fondo */
  .event-image-box {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    flex: 1; /* La imagen ocupa todo el espacio disponible */
  }
  
  /* Descripción y bloque alineados */
  .row.align-items-stretch > div {
    display: flex;
    flex-direction: column;
  }
  
  .location-image{
    width: 600px;
  }

  iframe {width: 600px;height: 400px;}

  /* Ajustes responsivos para mobile */
  @media (max-width: 768px) {
    .event-image-box {
      height: auto; /* Altura automática para mostrar toda la imagen */
      min-height: 335px; /* Asegura que sea visible incluso con poco contenido */
    }
    .location-image{
      width: 100%;
    }
    iframe {width: 100%;height: 300px;}
  
    

  }
  

