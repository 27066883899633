.landing-page {
    text-align: center;
    padding: 20px;
  }
  
  .system-selection {
    margin: 20px 0;
  }
  
  .system-image {
    width: 100px;
    margin: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .system-image:hover {
    transform: scale(1.2);
  }
  
  section {
    margin: 20px 0;
  }
  

  .img-tuto {width:40%}
  .img-faction {width: 5%;}

  @media (max-width: 768px) {
    .img-tuto {width: 100%;}
    .img-faction {width: 20%;}
  }