/* Contenedor principal */
.auth-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: auto;
  padding: 10px; /* Añadir espacio en los lados para mobile */
}

/* Tarjeta con animación 3D */
.card-container {
  width: 100%;
  max-width: 400px; /* Limitar el ancho máximo para mobile */
  height: 500px;
  perspective: 1000px;
  min-height: 450px; /* Altura mínima */
}

.card-side {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.6s;
  box-sizing: border-box,
}

/* Fondo inicial */
.card-side.front {
  background: url("../assets/images/Mesaevento3.jpg") no-repeat center;
  background-size: cover;
  color: white;
}

.card-side.back {
  transform: rotateY(180deg); 
  padding: 20px;
  min-height: auto,
}
.card-container .card-side {
  transition: transform 0.8s ease-in-out; /* Animación más lenta */
}

/* Animación al girar */
.card-container.flipped .front {
  transform: rotateY(-180deg);
}

.card-container.flipped .back {
  transform: rotateY(0);
}

/* Ajustes al formulario */
.auth-form {
  max-width: 360px;
  margin: 0 auto;
}

/* Texto adicional */
.under-text {
  font-size: 16px;
  text-align: center;
}


.alert {font-size: 13px;}

/* Estilos para pantallas grandes */
@media (min-width: 768px) {
  .auth-container {
    margin-top: 60px; /* Más espacio en pantallas grandes */
  }

  .card-container {
    max-width: 800px; /* Aumentar el ancho del contenedor */
    height: 500px; /* Proporcionalmente más alto */
  }

  .card-side {
    padding: 30px; /* Más espacio interior */
  }

  .auth-form {
    max-width: 700px; /* Ampliar el formulario */
    min-width: 60%;
  }

  /* Texto adicional */
.under-text {
  font-size: 16px;
  text-align: center;
}

}
