/* Estilos personalizados */
header {
  background: url('assets/images/banner1.jpg') no-repeat center center;
  background-size: cover;
  min-height: 50vh;
  background-position-y: -50vh;
}

header h1, header p {
  color: white; /* El color principal del texto */
  -webkit-text-stroke: 1.5px black; /* Borde negro */
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7); /* Sombra */
}


h2 {
  font-weight: bold;
}

.card {
  transition: transform 0.3s;
}

.card:hover {
  transform: translateY(-5px);
  transition: all 0.3s ease-in-out;
}

.react-multi-carousel-track > li {
  margin: 10px;
}

/* Contenedor de la sección */
.section-box {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}


/* Contenedor con borde y sombra */
.section-box {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover para el efecto moderno */
.section-box:hover {
  /* transform: translateY(-5px); */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
}

/* Texto centrado */
.section-box h2 {
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
}

.navbar {position: fixed!important; width: 100%;z-index: 99999999;}
.content {padding-top: 66px}
.anton-regular {
  font-family: "Anton", serif;
  font-weight: 400;
  font-style: normal;
}

.germania-one-regular {
  font-family: "Germania One", serif;
  font-weight: 400;
  font-style: normal;
}



@media (max-width: 464px) {
  .react-multi-carousel-track > li {
    margin: 0px;
    padding: 10px;
    
  }
  
}

/* Márgenes en dispositivos móviles */
@media (max-width: 768px) {
  .section-box {
    margin-bottom: 20px;
  }
  .content {padding-top: 66px}
  /* .img-event-list {min-width: 100px;} */
  header {
    background: url('assets/images/banner1.jpg') no-repeat center center!important;
    background-size: cover;
    min-height: 20vh;
  }
}
  